import commonHelper from '@/app/utility/common.helper.utility';
import addCostSheetTermCondition from './addCostSheetTermCondition';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'costSheetTermCondition',
  components: {
    addCostSheetTermCondition
  },
  watch: {
    currentPage: function () {
      this.getCostSheetData();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getCostSheetData();
    }
  },
  data() {
    return {
      payload: null,
      loader: false,
      unsubscribe: null,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      rowSelectedData: null,
      showCostSheetModal: false,
      showValueSetModal: false,
      state: {
        text: null,
        value: null
      },
      description: null,
      active: true,
      parent_value_set_id: null,
      costSheetData: [],
      costSheetFields: [
        {
          key: 'state_name',
          label: 'State'
        },
        {
          key: 'tc_description',
          label: 'Description'
        },
        {
          key: 'active'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'seq_no',
          label: 'Sequence No.'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowSelectedData = null;
          this.showHideCostSheetModal(true);
        }
        // if (actionName === 'download' && !this.showCostSheetModal) {
        //   this.loader = true;
        //   /**
        //    * @param(payload, 'action name', 'file name')
        //    */
        //   const downloadpayload = { ...this.payload };
        //   downloadpayload._limit = this.totalRows;
        //   this.downloadExcel.downloadData(
        //     downloadpayload,
        //     'masterData/getComponentMasterList',
        //     'component-master',
        //     () => (this.loader = false)
        //   );
        // }
      }
    });
  },
  methods: {
    getCostSheetData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        state_id: this.state.value,
        description: this.description,
        active: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getCostSheetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.costSheetData = response.data.data.page;
            this.costSheetData.active = response.data.data.page.map(
              data =>
                data.active ? 'Yes' :'No'
            );
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.parent_value_set_id = 100;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.state = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideCostSheetModal(flag) {
      this.showCostSheetModal = flag;
    },
    rowSelected(item) {
      this.rowSelectedData = item;
      this.showHideCostSheetModal(true);
    },
    clearSearch() {
      this.state = {
        text: null,
        value: null
      };
      this.description = null;
      this.costSheetData = [];
      this.totalRows = null;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.state.text = null;
        this.state.value = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
