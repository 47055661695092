import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'AddCostSheetTermCondition',
  components: {
    DatePicker
  },
  props: ['rowSelectedData'],
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      responseMsg: null,
      editMode: false,
      customerName: null,
      customerNumber: null,
      customerId: null,
      mappingIndex: null,
      defaultMapping: null,
      defaultMappingId: null,
      assetIndex: null,
      setTimeVsetCode: null,
      vsetCode: null,
      customerMappingId: 0,
      parent_value_set_id: null,
      showValueSetModal: false,
      showAddDunningMasterModal: false,
      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      loader: false,
      stateId: null,
      costSheetDetailData: [
        {
          state_name: null,
          state_id: null,
          tc_description: null,
          seq_no: null,
          fms_state_wise_min_wages_mpng_id: 0,
          start_date: null,
          end_date: null,
          active: true,
        }
      ],
      costSheetDetailFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'active',
          label: 'Active',
          stickyColumn: true
        },
        {
          key: 'state_name',
          label: 'State'
        },
        {
          key: 'tc_description',
          label: 'Description'
        },
        {
          key: 'seq_no',
          label: 'Sequence No.'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
      ]
    };
  },
  mounted() {
    if (this.rowSelectedData) {
      this.getCostSheetDataById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditCostSheetData();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getCostSheetDataById() {
      const payload = {
        fms_state_wise_min_wages_mpng_id: this.rowSelectedData.fms_state_wise_min_wages_mpng_id
      };
      this.loader = true;
      this.$store
        .dispatch(
          'masterData/getCostSheetDataById', payload
        )
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result.length) {
              this.costSheetDetailData = result.map(detail => {
                detail.start_date = commonHelper.formattedDate(detail.start_date)
                detail.end_date = commonHelper.formattedDate(detail.end_date)
                return detail;
              });
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditCostSheetData() {
      const custDetails = this.costSheetDetailData.map(elem => {
        return {
          fms_state_wise_min_wages_mpng_id: elem.fms_state_wise_min_wages_mpng_id,
          state_id: elem.state_id,
          active: elem.active,
          tc_description: elem.tc_description,
          start_date: commonHelper.formattedDate(elem.start_date),
          end_date: commonHelper.formattedDate(elem.end_date),
          seq_no: elem.seq_no
        };
      });
      const payload = {
        fms_state_wise_min_wages_mpng: custDetails ? custDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/addEditCostSheetData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.SELECT_STATE:
          if (this.mappingIndex >= 0) {
            this.costSheetDetailData[
              this.mappingIndex
            ].state_name = item.value_code;
            this.costSheetDetailData[this.mappingIndex].state_id =
              item.value_set_dtl_id;
            this.mappingIndex = null;
          }
      }
    },
    openValueSetModal(vsetCode) {
      // this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        this.parent_value_set_id = 100;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    chooseValueset(name, index) {
      this.mappingIndex = index;
      switch (name) {
        case 'state':
          this.openValueSetModal(appStrings.VALUESETTYPE.SELECT_STATE);
      }
    },
    addNewRow() {
      if (this.editMode) {
        this.costSheetDetailData.push({
          state_name: null,
          state_id: null,
          tc_description: null,
          seq_no: null,
          active: true,
          start_date: null,
          end_date: null,
          fms_state_wise_min_wages_mpng_id: 0,
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.costSheetDetailData.splice(index, 1);
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'state') {
        this.costSheetDetailData[index].state_name = null;
        this.costSheetDetailData[index].rule_id = null;
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
